<template>
  <b-container  fluid class="container" >
    <loading :active.sync="isWorking" :is-full-page="true"></loading>
    
    <b-row align-h="center" align="center">    
      <b-col>
        <h4 style="color:white; text-transform:capitalize; margin-top:15px;"><img style="margin-top:-7px;" height="20px;" :src="'../imgs/' + serviceInfo.icon" /> {{serviceInfo.nombre.toLowerCase()}}</h4>   
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <br>
        <b-card class=" card-service-info c-shadow-sm">
          <b-card-title class="title-card-info"><b-icon icon="file-text"/>
            Descripción del Trámite
            <h6><b-badge v-if="serviceInfo.linkPDF!=''" scale="4" variant="success" pill :href="serviceInfo.linkPDF" target="_blank">Cédula de Información <b-icon icon="cloud-arrow-down"/></b-badge></h6>
          </b-card-title>
          <div v-for="(parrafo, idx) in serviceInfo.descripcion.split('¬')" :key="idx">  
              <b-button  class="button-collapse-requisitos" block v-b-toggle="'collapset-'+idx" variant="secondary">{{parrafo.split('|')[0]}} </b-button>
              <b-collapse :id="'collapset-'+idx" style="width: 100%;" accordion="desc-acordion" role="tabpanel">
               
                  <b-card v-for="linea in parrafo.split('|')[1].split('\\')" :key="linea.substring(0,4)">
                    {{linea}}
                  </b-card>
               
              </b-collapse>
          </div>
          <br>
          <b-card-title class="title-card-info"><b-icon icon="list-ul"/> Requisitos</b-card-title>
            <!------- PERSONAS FISICAS -------->
            <b-button v-if="!isEduClient" block v-b-toggle="'collapse-1'" variant="secondary" class="button-collapse-requisitos">Personas Fisicas</b-button>
            <b-collapse id="collapse-1" style="width: 100%;" accordion="requisitos-acordion" role="tabpanel">
              <b-card>
                <div v-for="requisito in serviceInfo.requisitos" :key="requisito.idRequisito">
                  <b-card style="margin:-10px;" v-if="requisitoAplicaAPersonaFisica(requisito.objetivo)">
                    {{requisito.nombre}} <b :title="requisito.descripcion"><b-icon @click="showInfoRequisito(requisito.descripcion)" icon="question-circle-fill" font-scale="1.1" variant="success"></b-icon></b>
                  </b-card>
                </div>
              </b-card>
            </b-collapse>
            <!------- PERSONAS MORALES -------->
            <b-button v-if="!isEduClient" block v-b-toggle="'collapse-2'" variant="secondary" class="button-collapse-requisitos">Personas Morales</b-button>
            <b-collapse id="collapse-2" style="width: 100%;" accordion="requisitos-acordion" role="tabpanel">
              <b-card>
                <div v-for="requisito in serviceInfo.requisitos" :key="requisito.idRequisito">
                  <b-card style="margin:-10px;" v-if="requisitoAplicaAPersonaMoral(requisito.objetivo)">
                    {{requisito.nombre}} <b :title="requisito.descripcion"><b-icon @click="showInfoRequisito(requisito.descripcion)" icon="question-circle-fill" font-scale="1.1" variant="success"></b-icon></b>
                  </b-card>
                </div>
              </b-card>
            </b-collapse>
            <!------- INSTITUCIONES PUBLICAS -------->
            <b-button v-if="!isEduClient" block v-b-toggle="'collapse-3'" variant="secondary" class="button-collapse-requisitos">Instituciones Públicas</b-button>
            <b-collapse id="collapse-3" style="width: 100%;" accordion="requisitos-acordion" role="tabpanel">
              <b-card>
                <div v-for="requisito in serviceInfo.requisitos" :key="requisito.idRequisito">
                  <b-card style="margin:-10px;" v-if="requisitoAplicaAInstituciones(requisito.objetivo)">
                    {{requisito.nombre}} <b :title="requisito.descripcion"><b-icon @click="showInfoRequisito(requisito.descripcion)" icon="question-circle-fill" font-scale="1.1" variant="success"></b-icon></b>
                  </b-card>
                </div>
              </b-card>
            </b-collapse>
            <!------- INSTITUCION EDUCATIVA -------->
            <div  v-for="requisito in serviceInfo.requisitos" :key="requisito.idRequisito">
              <b-card v-if="isEduClient && requisitoAplicaAPersonaFisica(requisito.objetivo)">
                {{requisito.nombre}} <b :title="requisito.descripcion"><b-icon @click="showInfoRequisito(requisito.descripcion)" icon="question-circle-fill" font-scale="1.1" variant="success"></b-icon></b>
              </b-card>
            </div>
        </b-card>
      </b-col>
    </b-row>
    <!-- YA NO SE USA AHORA LA INFO SE AGREGA EN LA DESCRIPCION
    <b-row>
      <b-col cols="12">
        <b-card class="card card-service-info c-shadow-sm">
          <b-card-title class="title-card-info"><b-icon icon="file-earmark-medical"/> Documentos a obtener</b-card-title>
          <b-card v-for="documento in serviceInfo.entregables" :key="documento"><h6>{{documento}}</h6></b-card>
        </b-card>
      </b-col>
    </b-row>
    -->
    <b-row class="row-button" align-h="center">
      <b-col md="5" cols="12">
        <b-button pill block v-if="serviceInfo.isEnLinea && !isEduClient" class="btn-back-purple" :style=" {background: gradientColor }" size="lg" @click="showModalChoosePersonType">Iniciar Trámite en Línea <b-icon icon="arrow-right-circle"/></b-button><br>
        <b-button pill block v-if="serviceInfo.isEnLinea && isEduClient" class="btn-back-purple" size="lg" @click="iniciarTramite('F')">Iniciar Trámite en Línea <b-icon icon="arrow-right-circle"/></b-button><br>
        <h4 v-if="!serviceInfo.isEnLinea">Este trámite se realiza directamente en las oficinas administrativas</h4><br>
      </b-col>
    </b-row>

    <!--modals-->
    <b-modal title="Indica el tipo de persona que representas" v-model="isShowModalChoosePersonType" size="lg" hide-footer>
      <b-row align="center">
        <b-col md="4" cols="12" style="margin-bottom:15px;" class="col-tipo-persona" v-if="varExisteReqsParaFisica">
          <b-button class="btn-back-purple" block @click="iniciarTramite('F')"><b-icon font-scale="2" icon="person-fill"></b-icon><br>Soy una Persona<br>(Persona Física)</b-button>
        </b-col>
        <b-col md="4" cols="12" style="margin-bottom:15px;" class="col-tipo-persona" v-if="varExisteReqsParaMoral">
          <b-button class="btn-back-purple" block @click="iniciarTramite('M')"><b-icon font-scale="2" icon="diagram3-fill"></b-icon><br>Soy una Empresa<br>(Persona Moral)</b-button>
        </b-col>
        <b-col md="4" cols="12" class="col-tipo-persona" v-if="varExisteReqsParaInst">
          <b-button class="btn-back-purple" block @click="iniciarTramite('I')"><b-icon font-scale="2" icon="building"></b-icon><br>Soy una Institución<br> Pública</b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal title="Descripcion del requisito" v-model="isShowModalDescRequisito" size="md" hide-footer>
      {{descripcionRequisito}}
    </b-modal>
  </b-container>
</template>

<script>
  import { mapState} from 'vuex';
  import { addService } from '@/axios-tools';
  import Loading from 'vue-loading-overlay';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import Vue from 'vue';
  import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
  import { isTokenUserValid } from '@/auth-tools';
  import { requisitoAplicaAPersonaFisica, requisitoAplicaAInstituciones, requisitoAplicaAPersonaMoral } from '@/vue-tools';

  Vue.use(BootstrapVue)
  Vue.use(IconsPlugin)

  export default {
    computed: {
      ...mapState({
        serviceInfo: state => state.user.serviceInfo,
        token: state => state.user.token,
        perfil: state => state.user.perfil,
        isWorking: state => state.user.isWorking,
        msjErrorUser: state => state.user.msjErrorUser,
        gradientColor: state => state.user.gradientColor,
      }),
      iconBall: function(){
        return " display: block; width: 100%; height: 100%; align-items: center; "+ 
        "background: linear-gradient(90deg, rgba(153,121,241,1) 0%, rgba(104,101,233,1) 100%);" +
        "border-radius: 50%; " +
        "box-shadow: inset -25px -25px 40px rgba(10,10,30,.4); " +
        "background-image: -webkit-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -moz-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -o-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "background-image: -ms-linear-gradient(-45deg, rgba(255,255,220,.2) 0%, transparent 100%); " +
        "position:absolute; left: 0px; top:0px; text-align:center; ";
      },
      isEduClient(){ //Nos dice si nuestro cliente es una institucion educativa
        return this.cliente.substring(0,2) == "ED";
      },
    },
    mounted() {
      if(this.serviceInfo==null){
        this.$router.push('/'+location.pathname.split("/", 2).toString().replace(",", "")+"/catalogotramites");
      }
    },
    components: {
      Loading
    },
    data() {
      return {
        cliente: location.pathname.split("/", 2).toString().replace(",", ""),
        isShowModalChoosePersonType:false,
        isShowModalDescRequisito:false,
        descripcionRequisito:"",
        requisitoAplicaAPersonaFisica: requisitoAplicaAPersonaFisica,
        requisitoAplicaAInstituciones: requisitoAplicaAInstituciones,
        requisitoAplicaAPersonaMoral: requisitoAplicaAPersonaMoral,
        varExisteReqsParaFisica: false,
        varExisteReqsParaMoral: false,
        varExisteReqsParaInst:false
      }
    },
    methods: {
      showModalChoosePersonType(){
        if(isTokenUserValid(this.$store, this.$router)){
          this.varExisteReqsParaFisica=this.existeReqsParaFisica();
          this.varExisteReqsParaMoral=this.existeReqsParaMoral();
          this.varExisteReqsParaInst=this.existeReqsParaInst();
          this.isShowModalChoosePersonType=true;
        }
      },
      existeReqsParaFisica(){
        var existe=false;
        for(var idx in this.serviceInfo.requisitos){
          if(this.requisitoAplicaAPersonaFisica(this.serviceInfo.requisitos[idx].objetivo)){
            existe=true;
            break;
          }
        }
        return existe;
      },
      existeReqsParaMoral(){
        var existe=false;
        for(var idx in this.serviceInfo.requisitos){
          if(this.requisitoAplicaAPersonaMoral(this.serviceInfo.requisitos[idx].objetivo)){
            existe=true;
            break;
          }
        }
        return existe;
      },
      existeReqsParaInst(){
        var existe=false;
        for(var idx in this.serviceInfo.requisitos){
          if(this.requisitoAplicaAInstituciones(this.serviceInfo.requisitos[idx].objetivo)){
            existe=true;
            break;
          }
        }
        return existe;
      },
      showInfoRequisito(descRequisito){
        this.descripcionRequisito=descRequisito;
        this.isShowModalDescRequisito=true;
      },
      iniciarTramite(tipoPersona){/* Método que envia la petición al servidor para que un usuario se registre en el trámite */
        if(isTokenUserValid(this.$store, this.$router)){
          addService(this.$route.params.municipio, this.token, this.serviceInfo.idServiceInfo, this.$store, this.$router, tipoPersona);
        }else{
          this.$router.push('/' + this.$route.params.municipio + '/loginuser');
        }
      },
      goToCatalogo(){
        this.$router.push('/'+this.$route.params.municipio+'/catalogotramites')
      },
      goToLogin(){
        this.$router.push('/'+this.$route.params.municipio+'/loginuser')
      },
      goToPerfil(){
        this.$router.push('/'+this.$route.params.municipio+'/perfiluser')
      }

    }
  }
</script>
<style scoped>
.img-supposition-rounded{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #7C7C7C;
  border-radius: 50%;
  font-size:20px;
  width: 80px;
  height: 80px;
  position:absolute;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
.col-tipo-persona{
  margin-bottom:5px;
}
.button-collapse-requisitos{
  text-align:left; 
  margin-bottom:3px;
}
.title-card-info{
  color:#D97E00;
}
.card-service-info{
  width: 100%;
  margin-top:10px;
}
.row-button{
  margin-top:10px;
}
</style>